import { gql } from '@apollo/client/core';

export const PRODUCTION_CREATE_MANY = gql`
  mutation CreateProductionMany($object: [productions_insert_input!]!) {
    insert_productions(objects: $object) {
      affected_rows
    }
  }
`;

export const PRODUCTION_UPDATE = gql`
  mutation UpdateProduction($object: productions_set_input!, $id: Int!) {
    update_productions_by_pk(pk_columns: { id: $id }, _set: $object) {
      end
      area_id
      quantity
      start
      id
    }
  }
`;

export const PRODUCTIONS_DELETE = gql`
  mutation DeleteProduction($id: Int!) {
    delete_productions_by_pk(id: $id) {
      id
    }
  }
`;

export const PRODUCTIONS_DATES = gql`
  query GetAllProductionsDates($area_id: Int!) {
    productions(where: { area_id: { _eq: $area_id } }) {
      start
    }
  }
`;
