import dayjs from 'dayjs';
import localforage from 'localforage';
import { useI18n } from 'vue-i18n';

export const useProductionFilter = () => {
  // Composables
  const { t } = useI18n();

  // Variables
  const clientId = globalStore().getSelectedClient;
  const siteId = globalStore().getSelectedSite;
  const areaId = ref<number | null>(null);
  const energyType = ref<string | null>(null);
  const period = ref<{ start: Date | null; end: Date | null }>({
    start: null,
    end: null,
  });

  // Setters
  const setAreaId = async (id: number) => {
    productionStore().setAreaId(id);
    await localforage.setItem<number>(`nrjx-${clientId}-${siteId}-production-area`, id);
    const area = areaStore().getArea(id);
    if (!area.meters.find((meter) => meter.meter_type.energy_type === energyType.value)) {
      energyType.value = area?.meters[0].meter_type.energy_type;
      productionStore().setEnergyType(energyType.value);
    }
  };

  const setEnergyType = async (type: string) => {
    productionStore().setEnergyType(type);
    await localforage.setItem<string>(`nrjx-${clientId}-${siteId}-production-energy-type`, type);
  };

  const setPeriod = async (newPeriod: { start: Date; end: Date }) => {
    productionStore().setPeriod(newPeriod);
    const data = JSON.stringify(newPeriod);
    await localforage.setItem<string>(`nrjx-${clientId}-${siteId}-production-period`, data);
  };

  // Getters
  const getAreaInForage = async () => {
    const areaForage = await localforage.getItem<number>(`nrjx-${clientId}-${siteId}-production-area`);
    if (areaForage) {
      areaId.value = areaForage;
    } else {
      areaId.value = areaStore().getAreas[0].id;
    }
    productionStore().setAreaId(areaId.value);
  };

  const getEnergyTypeInForage = async () => {
    const energyTypeForage = await localforage.getItem<string>(`nrjx-${clientId}-${siteId}-production-energy-type`);
    if (energyTypeForage) {
      const area = areaStore().getArea(areaId.value);
      if (!area.meters.find((meter) => meter.meter_type.energy_type === energyTypeForage)) {
        energyType.value = area?.meters[0].meter_type.energy_type;
      } else {
        energyType.value = energyTypeForage;
      }
    } else {
      energyType.value = 'Electricity';
    }
    productionStore().setEnergyType(energyType.value);
  };

  const getPeriodInForage = async () => {
    const periodForage = await localforage.getItem<string>(`nrjx-${clientId}-${siteId}-production-period`);
    const data = JSON.parse(periodForage);
    if (data) {
      period.value = data;
    } else {
      period.value = {
        start: dayjs().subtract(1, 'month').toDate(),
        end: dayjs().toDate(),
      };
    }
    productionStore().setPeriod(period.value);
  };

  const getAreaDisabled = computed(() => {
    const areas = areaStore().getAreas;
    return areas.filter((area) => ['utility', 'peripheral'].includes(area.area_type)).map((area) => area.id);
  });

  const getEnergyTypes = computed(() => {
    return areaStore()
      .getArea(areaId.value)
      ?.meters.map((meter) => ({
        label: t(`global.energy_type.${meter.meter_type.energy_type}`),
        key: meter.meter_type.energy_type,
      }));
  });

  // Lifecycle
  onBeforeMount(async () => {
    await getAreaInForage();
    await getPeriodInForage();
    await getEnergyTypeInForage();
  });

  return {
    setAreaId,
    setEnergyType,
    getAreaInForage,
    getEnergyTypeInForage,
    getPeriodInForage,
    setPeriod,
    getAreaDisabled,
    getEnergyTypes,
    period,
    areaId,
    energyType,
  };
};
