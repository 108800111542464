<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

// Components
import productionTable from './../table/Production.vue';
import productionChart from './../chart/Production.vue';
import productionManage from './../modal/Production.vue';
import type { NuxtError } from '#app';

// Composables
const { t } = useI18n();
const router = useRouter();

// Props
defineProps<{
  error: NuxtError | undefined;
}>();

// Data
const showAddProductionModal = ref(false);

// Computed
const area = computed(() => areaStore().getArea(productionStore().getAreaId));
const loading = computed(() => productionStore().getLoading);
const canAddProduction = computed(() => {
  if (productionStore().getProductionPlan?.plan_type === 'FabricationOrderLookup' || !productionStore().getProductionPlan?.plan_type) {
    return false;
  }
  return true;
});
const kpis = computed(() => {
  const productions = productionStore().getProductions;
  const totalConsumption = productions.reduce((acc, curr) => acc + (curr.consumption || 0), 0);
  const totalPower = productions.reduce((acc, curr) => acc + (curr.avg_power || 0), 0);
  const totalQuantity = productions.reduce((acc, curr) => acc + (parseFloat(curr.production.quantity) || 0), 0);

  return [
    {
      kpi: `${useNumbers().formatNumberToIsoNumber(totalConsumption)} kWh`,
      title: t('production.total_production'),
    },
    {
      kpi: `${useNumbers().formatNumberToIsoNumber(totalPower)} kW`,
      title: t('production.average_power'),
    },
    {
      kpi: `${useNumbers().formatNumberToIsoNumber(totalQuantity)} ${area.value?.production_unit?.symbol ?? '--'}`,
      title: t('production.total_quantity'),
    },
  ];
});

const addProduction = () => {
  showAddProductionModal.value = true;
};
</script>
<template>
  <div>
    <ui-card :title="$t('production.production')">
      <template #header>
        <ui-button left-icon="Plus" :color="canAddProduction ? 'primary' : 'disabled'" @click="addProduction">
          {{ $t('production.add_production') }}
        </ui-button>
      </template>

      <template v-if="!loading">
        <ui-warning
          v-if="!productionStore().getProductionPlan"
          :message="$t('production.the_production_of_this_area_can_not_be_deduced_with_the_following_data')"
          :link="$t('production.configure_the_flux')"
          class="mb-4"
          @click-link="router.push({ name: 'config-areas-index' })"
        />
        <ui-warning
          v-else-if="!area?.production_unit?.symbol"
          :message="$t('production.you_dont_have_a_production_unit_for_this_area')"
          :link="$t('production.configure_the_production_unit')"
          class="mb-4"
          @click-link="router.push({ name: 'area-manage', params: { id: area.id } })"
        />
        <ui-error v-else-if="productionStore().getError" :title="productionStore().getError.message" class="mb-4" />
      </template>

      <div class="grid grid-cols-4 gap-4">
        <production-chart class="col-span-3 row-span-1" />
        <div class="col-span-1 row-span-1 flex flex-col gap-4">
          <ui-kpi v-for="kpi in kpis" :key="kpi.title" :kpi="kpi.kpi" :title="kpi.title" />
        </div>
        <div class="col-span-4">
          <production-table />
        </div>
      </div>
    </ui-card>
    <production-manage v-model="showAddProductionModal" />
  </div>
</template>
