import dayjs from 'dayjs';
import { PRODUCTION_CREATE_MANY, PRODUCTION_UPDATE, PRODUCTIONS_DELETE, PRODUCTIONS_DATES } from '~/graphql/production';

export const useProductionAPI = () => {
  const { client } = useApiV2();
  const { apolloClient } = useApollo();

  const getProductions = async () => {
    productionStore().setLoading(true);
    productionStore().setError(null);
    const response = await client.GET('/v2/areas/{area_id}/productions/epi/', {
      params: {
        path: { area_id: productionStore().getAreaId },
        query: {
          start: dayjs(productionStore().getPeriod.start).format('YYYY-MM-DD'),
          end: dayjs(productionStore().getPeriod.end).format('YYYY-MM-DD'),
          energy_type: productionStore().getEnergyType,
        },
      },
    });
    productionStore().setProductionPlan(response.data?.data?.plan ?? null);
    productionStore().setProductions(response.data?.data?.productions ?? []);

    productionStore().setError(response.data.error);

    await getAllProductionsDates();
    productionStore().setLoading(false);
  };

  const getAllProductionsDates = async () => {
    let areaId;
    const plan_type = productionStore().getProductionPlan?.plan_type;

    if (!plan_type) {
      return;
    }

    if (plan_type !== 'ProductionSum') {
      areaId = productionStore().getAreaId;
    } else {
      areaId = productionStore().getProductionPlan.area_ids[0];
    }

    const response = await apolloClient.query({
      query: PRODUCTIONS_DATES,
      variables: {
        area_id: areaId,
      },
    });

    productionStore().setProductionsDates(response.data.productions.map((value) => value.start));
  };

  const createProduction = async (params: { area_id: number; start: string; end: string; quantity: number }[]) => {
    await apolloClient.mutate({
      mutation: PRODUCTION_CREATE_MANY,
      variables: {
        object: params.map((value) => ({
          area_id: value.area_id,
          start: dayjs(value.start).format('YYYY-MM-DD'),
          end: dayjs(value.end).format('YYYY-MM-DD'),
          quantity: value.quantity,
        })),
      },
    });
  };

  const updateProduction = async (params: { id: number; object: { area_id: number; start: string; end: string; quantity: number } }) => {
    await apolloClient.mutate({
      mutation: PRODUCTION_UPDATE,
      variables: {
        id: params.id,
        object: {
          area_id: params.object.area_id,
          start: params.object.start,
          end: params.object.end,
          quantity: params.object.quantity,
        },
      },
    });
  };

  const deleteProduction = async (id: number) => {
    await apolloClient.mutate({
      mutation: PRODUCTIONS_DELETE,
      variables: {
        id: id,
      },
    });
  };

  return { getProductions, createProduction, updateProduction, deleteProduction, getAllProductionsDates };
};
