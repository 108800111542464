import type { ChartOptions } from 'chart.js';
import dayjs from 'dayjs';
import colors from 'tailwindcss/colors';
import { useI18n } from 'vue-i18n';
import type { Area } from '~/types/area';
import type { ProductionsWithEPI } from '~/types/production';

export const useProductionChart = () => {
  // Composables
  const { t } = useI18n();

  const options = (productions: ProductionsWithEPI['productions'], area: Area, energyType: string): ChartOptions => {
    return {
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
          },
          grace: '25%',
          border: {
            display: false,
          },
          ticks: {
            color: colors.gray[400],
          },
        },
        x: {
          ticks: {
            callback: (value) => {
              if (productions[value] && area?.production_reporting_frequency === 'day') {
                return dayjs(productions[value].production.start).format('DD/MM/YYYY');
              } else if (productions[value]) {
                return (
                  dayjs(productions[value].production.start).format('DD/MM/YYYY') +
                  ' - ' +
                  dayjs(productions[value].production.end).format('DD/MM/YYYY')
                );
              }
              return '';
            },
            color: colors.gray[400],
          },
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              return `${context.formattedValue} kWh / ${area.production_unit?.symbol ?? '--'}`;
            },
            title: (context) => {
              if (productions[context[0].dataIndex] && area?.production_reporting_frequency === 'day') {
                return dayjs(productions[context[0].dataIndex].production.start).format('DD/MM/YYYY');
              } else if (productions[context[0].dataIndex]) {
                return (
                  dayjs(productions[context[0].dataIndex].production.start).format('DD/MM/YYYY') +
                  ' - ' +
                  dayjs(productions[context[0].dataIndex].production.end).format('DD/MM/YYYY')
                );
              }
              return '';
            },
            footer: (context) => {
              const footer = [];
              const index = context[0].dataIndex;

              const meter = areaStore()
                .getArea(area.id)
                .meters.find((m) => m.meter_type.energy_type === energyType);
              const co2Value = useCO2().getCo2byKWhByMeterId(meter.id);

              if (!productions) return footer;
              footer.push(`${t('global.consumption')} : ${productions[index].consumption.toFixed(2)} kWh`);
              footer.push(
                `${t('global.quantity')} : ${parseFloat(productions[index].production.quantity).toFixed(2)} ${area.production_unit?.symbol ?? '--'}`,
              );
              footer.push(
                `${t('production.epi_in_co2')} : ${(productions[index].consumption_per_unit * co2Value).toFixed(2)} gCO2/${area.production_unit?.symbol ?? '--'}`,
              );
              footer.push(
                `${t('production.epi_in_kwh')} : ${productions[index].consumption_per_unit.toFixed(2)} kWh/${area.production_unit?.symbol ?? '--'}`,
              );
              return footer;
            },
          },
        },
      },
    };
  };

  return {
    options,
  };
};
