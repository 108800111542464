import createClient, { type Middleware } from 'openapi-fetch';
import type { paths } from '@nrjx/api';

export const useApiV2 = () => {
  const runtimeConfig = useRuntimeConfig();

  // Get token synchronously to ensure it's available when making requests
  const token = firebaseStore().getAccessToken;
  const authMiddleware: Middleware = {
    async onRequest({ request }) {
      request.headers.set('Authorization', `Bearer ${token}`);
      return request;
    },
    async onResponse({ response }) {
      const { body, ...resOptions } = response;
      return new Response(body, { ...resOptions, status: 200 });
    },
  };

  const client = createClient<paths>({
    baseUrl: runtimeConfig.public.apiV2Url,
    headers: {},
  });
  client.use(authMiddleware);

  return {
    client,
  };
};
