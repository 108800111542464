<script setup lang="ts">
// Components
import CardProduction from '../components/card/Production.vue';

// Composables
const { getProductions } = useProductionAPI();
const {
  period,
  areaId,
  getEnergyTypes,
  energyType,
  setEnergyType,
  getAreaInForage,
  getEnergyTypeInForage,
  getPeriodInForage,
  setAreaId,
  setPeriod,
} = useProductionFilter();

// API
const { error, execute } = useAsyncData('production', () => getProductions(), {
  immediate: false,
});

// Methods
const setAreaAndExecute = async (id: number) => {
  await setAreaId(id);
  await execute();
};

const setEnergyTypeAndExecute = async (type: string) => {
  await setEnergyType(type);
  await execute();
};

onMounted(async () => {
  await getAreaInForage();
  await getEnergyTypeInForage();
  await getPeriodInForage();
  execute();
});
</script>
<template>
  <ui-page :title="$t('production.production')">
    <template #header-actions>
      <ui-period-selector v-model="period" @update-period="(setPeriod($event), execute())">
        {{ $t('global.period') }}
      </ui-period-selector>
      <menu-area v-model="areaId" @update:model-value="setAreaAndExecute($event)" />
      <ui-dropdown
        v-model="energyType"
        :items="getEnergyTypes ?? []"
        :disabled="getEnergyTypes?.length <= 1"
        @update:model-value="setEnergyTypeAndExecute($event)"
      />
    </template>
    <card-production :area-id="areaId" :error="error" />
  </ui-page>
</template>
