<script setup lang="ts">
import type { ProductionsWithEPI } from '~/types/production';
import { useI18n } from 'vue-i18n';

// Components
import productionManage from '../modal/Production.vue';
import dayjs from 'dayjs';

// Composables
const { deleteProduction, getProductions } = useProductionAPI();
const { headers, actions, seletedUnit, getEPIListAsOption, getEPI } = useProductionTable();
const { t } = useI18n();

// Data
const showAddProductionModal = ref(false);
const productionId = ref<number | null>(null);

// Store references
const productionStoreRef = productionStore();
const areaStoreRef = areaStore();

// Computed
const loading = computed(() => productionStoreRef.getLoading);
const area = computed(() => areaStoreRef.getArea(productionStoreRef.getAreaId));
const isProductionLookup = computed(() => productionStoreRef.getProductionPlan?.plan_type === 'ProductionLookup');

// Optimisation du computed productions pour éviter les recalculs inutiles
const productions = computed(() => {
  const prods = [...productionStoreRef.getProductions];
  return prods.sort((a, b) => dayjs(b.production.start).valueOf() - dayjs(a.production.start).valueOf());
});

// Methods
const handleAction = async (action: string, item: ProductionsWithEPI['productions'][0]) => {
  if (action === 'edit') {
    productionId.value = item.production.id;
    showAddProductionModal.value = true;
    return;
  }

  if (action === 'delete') {
    appAlertStore().showAlert({
      type: 'error',
      title: t('global.delete'),
      message: t('production.are_you_sure_you_want_to_delete_this_production'),
      button: t('production.delete_the_production'),
      callback: async () => {
        try {
          await deleteProduction(item.production.id);
          await getProductions();
          toastStore().showToast({
            type: 'success',
            title: t('global.success'),
            message: t('production.production_deleted'),
          });
        } catch (error) {
          toastStore().showToast({
            type: 'error',
            title: t('global.error'),
            message: t('global.something_went_wrong'),
          });
          throw error;
        }
      },
    });
  }
};
</script>

<template>
  <ui-data-table :loading="loading" :items="productions" :headers="headers">
    <!-- Header -->
    <template #header-epi>
      <ui-dropdown v-model="seletedUnit" size="sm" :items="getEPIListAsOption()" :placeholder="$t('product.headers.epi_average')" />
    </template>

    <!-- Body -->
    <template #item-start="{ item }">{{ dayjs(item.production.start).format('DD/MM/YYYY') }}</template>
    <template #item-end="{ item }">{{ dayjs(item.production.end).format('DD/MM/YYYY') }}</template>
    <template #item-quantity="{ item }">
      {{ useNumbers().formatNumberToIsoNumber(parseFloat(item.production.quantity)) }} {{ area?.production_unit?.symbol ?? '--' }}
    </template>
    <template #item-avg_power="{ item }">{{ useNumbers().formatNumberToIsoNumber(item.avg_power) }} kW</template>
    <template #item-consumption="{ item }">{{ useNumbers().formatNumberToIsoNumber(item.consumption) }} kWh</template>
    <template #item-epi="{ item }">
      <ui-badge v-if="item.consumption === 0" color="orange">
        {{ $t('global.no_data') }}
      </ui-badge>
      <template v-else>{{ getEPI(item, seletedUnit) }}</template>
    </template>

    <template v-if="isProductionLookup" #item-action="{ item }">
      <ui-data-table-actions :items="actions" @click-item="handleAction($event, item)" />
    </template>
  </ui-data-table>

  <!-- Modal -->
  <production-manage v-if="showAddProductionModal" v-model="showAddProductionModal" :production-id="productionId" />
</template>
