<script lang="ts" setup>
import { onClickOutside, useElementBounding } from '@vueuse/core';
import type { Area } from '~/types/area';

// Props
const areaId = defineModel<number | null>();
const props = defineProps<{
  areaDisabled?: number[];
}>();

// Stores
const areaService = areaStore();

// Data
const principalMenu = ref(null);
const container = ref(null);
const modal = ref(false);
const subMenus = ref<Area[]>([]);
const { width } = useElementBounding(principalMenu);

// Computed
const currentArea = computed(() => areaService.getArea(areaId.value));
const rootArea = computed(() => areaService.getRootAreas[0]);

const getParentsOfSelectedArea = computed(() => {
  let area = currentArea.value;
  const parents: number[] = [];
  while (area?.parent_id) {
    area = areaService.getArea(area.parent_id);
    parents.push(area.id);
  }
  return parents;
});

// Methods
const getArea = (areaId: number) => {
  return areaService.getArea(areaId);
};

const clickItem = (id: number) => {
  const area = areaService.getAreas.find((area) => area.id === id);
  if (!area) return;

  if (area.children.length > 0) {
    if (subMenus.value.length === 0 || !subMenus.value.find((subMenu) => subMenu.id === area.id)) {
      const parents = getParentsOfArea(id).sort((a, b) => {
        if (a.parent_id === null) return -1;
        if (b.parent_id === null) return 1;
        if (a.id === b.parent_id) return -1;
        if (b.id === a.parent_id) return 1;
        return 0;
      });
      subMenus.value = parents.filter((parent) => parent.parent_id !== null);
      subMenus.value.push(area);
    }
  } else {
    if (isAreaDisabled(id)) return;
    areaId.value = id;
    principalMenu.value = false;
    subMenus.value = [];
    modal.value = false;
  }
};

const isAreaDisabled = (areaId: number) => {
  const area = areaService.getArea(areaId);
  if (area.children.length > 0) {
    return false;
  }
  return props.areaDisabled?.includes(areaId);
};

const clickRootItem = (id: number) => {
  areaId.value = id;
  principalMenu.value = false;
  subMenus.value = [];
  modal.value = false;
};

const selectSubMenu = (id: number) => {
  areaId.value = id;
  principalMenu.value = false;
  subMenus.value = [];
  modal.value = false;
};

const getParentsOfArea = (areaId: number) => {
  let area = areaService.getArea(areaId);
  const parents: Area[] = [];
  while (area.parent_id) {
    area = areaService.getArea(area.parent_id);
    parents.push(area);
  }
  return parents;
};

const openMenu = () => {
  modal.value = true;
  if (currentArea.value) {
    const parents = getParentsOfArea(currentArea.value.id).sort((a, b) => {
      if (a.parent_id === null) return -1;
      if (b.parent_id === null) return 1;
      if (a.id === b.parent_id) return -1;
      if (b.id === a.parent_id) return 1;
      return 0;
    });

    const parentsWithChildren = parents.filter((parent) => parent.parent_id !== null && parent.children && parent.children.length > 0);
    subMenus.value = parentsWithChildren;

    if (currentArea.value.parent_id && currentArea.value.children && currentArea.value.children.length > 0) {
      subMenus.value.push(currentArea.value);
    }
  }
};

onClickOutside(container, () => {
  modal.value = false;
  subMenus.value = [];
});
</script>
<template>
  <div class="relative select-none">
    <ui-button color="secondary" size="sm" @click="openMenu"> {{ $t('global.area') }}: {{ currentArea?.name }} </ui-button>
    <div ref="container">
      <div
        v-if="modal"
        ref="principalMenu"
        class="absolute p-1 text-sm text-gray-700 right-0 max-h-[300px] overflow-y-auto origin-top-left bg-white z-[9999] mt-2 px-1 rounded-[8px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div
          class="p-2 cursor-pointer rounded hover:bg-gray-100 select-none"
          :class="[rootArea.id === areaId ? 'bg-green-50 border-green-200 text-green-700 border' : '']"
          @click="clickRootItem(rootArea.id)"
        >
          {{ rootArea.name }}
        </div>
        <div v-if="rootArea?.children.length > 0" class="w-full my-1 h-px bg-gray-200" />
        <div
          v-for="children in rootArea?.children"
          :key="`area-${children.id}`"
          class="cursor-pointer flex items-center rounded hover:bg-gray-100 h-[36px]"
          :class="[
            getArea(children.id)?.children?.length > 0 ? 'pl-1 pr-2' : 'px-2',
            subMenus.find((subMenu) => subMenu.id === children.id) ? 'bg-gray-100' : '',
            children.id === currentArea?.id || getParentsOfSelectedArea.includes(children.id)
              ? 'bg-green-50 border-green-200 text-green-700 border'
              : '',
            isAreaDisabled(children.id) ? 'opacity-50' : '',
          ]"
          @click="clickItem(children.id)"
        >
          <ui-icon v-if="getArea(children.id).children.length > 0" name="ChevronLeft" class="mr-1 w-4" />
          <p class="text-sm whitespace-nowrap">{{ children.name }}</p>
        </div>
      </div>

      <!-- SubMenus -->
      <div
        v-if="subMenus.length > 0"
        :style="`right: ${width + 8}px`"
        class="absolute right-0 origin-top-left z-[9999] flex flex-row-reverse gap-2"
      >
        <div
          v-for="subMenu in subMenus"
          :key="`sub-menu-${subMenu.id}`"
          class="bg-white rounded-[8px] mt-2 px-1 p-1 text-sm text-gray-700 max-h-[300px] overflow-y-auto shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none inline-block whitespace-nowrap"
        >
          <div
            class="p-2 cursor-pointer rounded hover:bg-gray-100"
            :class="[
              subMenu.id === currentArea?.id ? 'bg-green-50 border-green-200 text-green-700 border' : '',
              props.areaDisabled?.includes(subMenu.id) ? 'opacity-50' : '',
            ]"
            @click="selectSubMenu(subMenu.id)"
          >
            {{ $t('global.all_areas') }}
          </div>
          <div class="w-full my-1 h-px bg-gray-200" />
          <div
            v-for="children in subMenu.children"
            :key="`area-${children.id}`"
            class="cursor-pointer flex items-center rounded hover:bg-gray-100 h-[36px] whitespace-nowrap"
            :class="[
              getArea(children.id).children.length > 0 ? 'pl-1 pr-2' : 'px-2',
              subMenus.find((subMenu) => subMenu.id === children.id) ? 'bg-gray-100' : '',
              children.id === currentArea?.id || getParentsOfSelectedArea.includes(children.id)
                ? 'bg-green-50 border-green-200 text-green-700 border'
                : '',
              isAreaDisabled(children.id) ? 'opacity-50' : '',
            ]"
            @click="clickItem(children.id)"
          >
            <ui-icon v-if="getArea(children.id).children.length > 0" name="ChevronLeft" class="mr-1 w-4" />
            <p class="text-sm">{{ children.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
