import { useI18n } from 'vue-i18n';
import type { Header } from '~/components/ui/data/DataTable.vue';
import type { TableAction } from '~/components/ui/data/DataTableActions.vue';
import type { ProductionsWithEPI } from '~/types/production';

export const useProductionTable = () => {
  const { t } = useI18n();
  const seletedUnit = ref<'consumption_per_unit' | 'cost_per_unit' | 'co2_per_unit'>('consumption_per_unit');

  // Headers
  const headers = computed(() => {
    const options: Header[] = [
      { label: t('global.start_date'), key: 'start' },
      { label: t('global.end_date'), key: 'end' },
      { label: t('production.production'), key: 'quantity' },
      { label: t('production.epi'), key: 'epi' },
      { label: t('production.average_power'), key: 'avg_power' },
      { label: t('global.consumption'), key: 'consumption' },
    ];

    if (productionStore().getProductionPlan?.plan_type === 'ProductionLookup') {
      options.push({ label: '', key: 'action', className: 'center w-[100px]' });
    }
    return options;
  });

  // Actions
  const actions: TableAction[] = [
    { label: t('global.edit'), icon: 'Edit', key: 'edit' },
    { label: t('global.delete'), icon: 'Trash', key: 'delete', className: 'text-red-500', iconClassName: 'stroke-red-500' },
  ];

  // Methods
  const getEPIListAsOption = (): { key: string; label: string }[] => {
    const options = [
      {
        key: 'consumption_per_unit',
        label: 'kWh',
      },
      {
        key: 'cost_per_unit',
        label: '€',
      },
      {
        key: 'co2_per_unit',
        label: 'gCO2',
      },
    ];

    return options;
  };

  const getEPI = (item: ProductionsWithEPI['productions'][0], type: 'consumption_per_unit' | 'cost_per_unit' | 'co2_per_unit') => {
    if (type === 'co2_per_unit') {
      const meter = areaStore()
        .getArea(productionStore().getAreaId)
        .meters.find((m) => m.meter_type.energy_type === productionStore().getEnergyType);
      const co2Value = useCO2().getCo2byKWhByMeterId(meter.id);
      return (co2Value * item.consumption_per_unit).toFixed(2) + ' gCO2';
    }
    const unit = type === 'consumption_per_unit' ? 'kWh' : '€';
    const area = areaStore().getArea(productionStore().getAreaId);

    if (typeof item[type] === 'number') {
      return item[type].toFixed(2) + ' ' + unit + ' / ' + (area?.production_unit?.symbol ?? '');
    }
    return item[type] + ' ' + unit + ' / ' + (area.production_unit?.symbol ?? '');
  };

  return {
    actions,
    headers,
    seletedUnit,
    getEPIListAsOption,
    getEPI,
  };
};
