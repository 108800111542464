<script setup lang="ts">
import dayjs from 'dayjs';
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';

// Composables
const { t } = useI18n();
const { areaId } = useProductionFilter();
const { createProduction, updateProduction, getProductions } = useProductionAPI();
const { getChildReportingFrequency } = useProductionForm();

// Props & emits
const value = defineModel<boolean>();
const props = defineProps<{
  productionId?: number;
}>();

// Data
const loading = ref(false);

// Form
const { handleSubmit, resetForm } = useForm<{
  start: string;
  quantity: number[];
}>({
  validationSchema: yup.object({
    start: yup.string().required(t('global.required_field')).typeError(t('global.invalid_date')),
    quantity: yup
      .array()
      .of(
        yup
          .number()
          .min(0, t('production.prodcution_quantity_can_t_be_null'))
          .when('$exit', {
            is: () => productionPlan.value?.plan_type === 'ProductionSum',
            then: (schema) => schema.required(t('global.required_field')),
            otherwise: (schema) => schema.optional(),
          }),
      )
      .when('$exit', {
        is: () => productionPlan.value?.plan_type === 'ProductionSum',
        then: (schema) => schema.required(t('global.required_field')),
        otherwise: (schema) => schema.optional(),
      }),
  }),
});

// Computed
const area = computed(() => areaStore().getArea(areaId.value));
const production = computed(() => productionStore().getProduction(props.productionId));
const productionPlan = computed(() => productionStore().getProductionPlan);

const reportingFrequency = computed((): 'day' | 'week' | 'month' => {
  if (!area.value?.production_reporting_frequency) {
    return getChildReportingFrequency(productionPlan.value?.area_ids);
  }
  return area.value?.production_reporting_frequency;
});

// Methods
const getArea = (id: number) => areaStore().getArea(id);
const submit = handleSubmit(async (values) => {
  loading.value = true;

  const endValue = () => {
    if (reportingFrequency.value === 'day') {
      return dayjs(values.start).format('YYYY-MM-DD');
    } else if (reportingFrequency.value === 'week') {
      return dayjs(values.start).add(6, 'days').format('YYYY-MM-DD');
    } else if (reportingFrequency.value === 'month') {
      return dayjs(values.start).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD');
    }
  };

  const object = productionPlan.value?.area_ids.map((value, index) => ({
    start: values.start,
    area_id: value,
    end: endValue(),
    quantity: values.quantity[index],
  }));

  try {
    if (!props.productionId) {
      await createProduction(object);
      toastStore().showToast({
        type: 'success',
        title: t('global.success'),
        message: t('production.production_created'),
      });
      await getProductions();
    } else {
      await updateProduction({
        id: production.value.production.id,
        object: {
          area_id: area.value.id,
          start: values.start,
          end: endValue(),
          quantity: values.quantity[0],
        },
      });
      toastStore().showToast({
        type: 'success',
        title: t('global.success'),
        message: t('production.production_updated'),
      });
      await getProductions();
    }
    value.value = false;
  } catch (error) {
    toastStore().showToast({
      type: 'error',
      title: t('global.error'),
      message: t('global.something_went_wrong'),
    });
    throw error;
  }
  loading.value = false;
});

watch(
  [() => props.productionId, () => value.value],
  () => {
    if (production.value) {
      resetForm({
        values: {
          start: dayjs(production.value.production.start).format('YYYY-MM-DD'),
          quantity: [Number(production.value.production.quantity)],
        },
      });
    } else {
      resetForm();
    }
  },
  { immediate: true },
);
</script>
<template>
  <ui-modal v-model="value" width="600" :title="production ? $t('production.update_the_production') : $t('production.add_the_production')">
    <div class="grid grid-cols-1">
      <i18n-t
        v-if="productionPlan?.plan_type === 'ProductionSum'"
        class="text-sm text-gray-500 mb-3"
        keypath="production.production_add_production_information_message"
        tag="p"
      >
        <template #area>
          <strong class="text-gray-500 font-semibold">{{ area?.name }}</strong>
        </template>
      </i18n-t>

      <ui-form-input-date-new
        name="start"
        :selection-type="reportingFrequency"
        :label="$t('global.date')"
        :rules="[
          {
            type: 'disabled-days',
            value: productionStore().getProductionsDates,
            message: t('production.this_date_is_already_used'),
            color: 'text-amber-500 bg-amber-50',
          },
          {
            type: 'disabled-days-after',
            value: [dayjs().toDate()],
          },
        ]"
      />

      <template v-if="productionPlan?.plan_type !== 'ProductionSum'">
        <ui-form-input-text
          name="quantity[0]"
          placeholder="2000"
          type="number"
          :label="$t('production.production_quantity')"
          :right-text="area?.production_unit?.symbol"
        />
      </template>

      <template v-else>
        <label class="text-xs text-gray-600 font-semibold mb-2">{{ $t('global.areas') }}</label>
        <div class="border border-gray-200 flex-1 bg-gray-50 rounded p-2 flex flex-col gap-2 mb-2 max-h-[400px] overflow-y-auto">
          <div v-for="(children, index) in productionPlan?.area_ids" :key="children" class="border border-gray-200 bg-white rounded p-2">
            <ui-badge color="green" class="mb-2">{{ getArea(children)?.name }}</ui-badge>
            <div v-if="productionPlan.elems[index].plan_type === 'FabricationOrderLookup'" class="text-xs text-gray-600">
              {{ t('production.production_deducted_from_fabrication_orders') }}
            </div>
            <ui-form-input-text
              v-else
              hide-details
              :name="`quantity[${index}]`"
              :label="$t('production.production_quantity')"
              placeholder="2000"
              type="number"
              :right-text="area?.production_unit?.symbol"
            />
          </div>
        </div>
      </template>
    </div>
    <template #footer>
      <ui-button type="submit" :loading="loading" @click="submit()">
        {{ props.productionId ? $t('production.update_the_production') : $t('production.add_the_production') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
