export const useProductionForm = () => {
  const getChildReportingFrequency = (areaIds: number[]): 'day' | 'week' | 'month' => {
    let frequency: 'day' | 'week' | 'month';
    areaIds.every((id) => {
      const area = areaStore().getArea(id);
      if (frequency === undefined && area.production_reporting_frequency) {
        frequency = area.production_reporting_frequency;
      } else if (frequency !== area.production_reporting_frequency) {
        return false;
      }
      return true;
    });
    return frequency;
  };

  return {
    getChildReportingFrequency,
  };
};
