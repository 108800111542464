<script setup lang="ts">
import dayjs from 'dayjs';

// Composables
const { options } = useProductionChart();

// Computed
const loading = computed(() => productionStore().getLoading);
const area = computed(() => areaStore().getArea(productionStore().getAreaId));
const getOptions = computed(() => options(productionStore().getProductions, area.value, productionStore().getEnergyType));
const productions = computed(() =>
  productionStore().getProductions.sort(
    (a, b) => dayjs(a.production.start).toDate().getTime() - dayjs(b.production.start).toDate().getTime(),
  ),
);
const timestamps = computed(
  () =>
    productions.value.map((production) => {
      if (production && area.value?.production_reporting_frequency === 'day') {
        return dayjs(production.production.start).format('DD/MM/YYYY');
      } else if (production) {
        return dayjs(production.production.start).format('DD/MM/YYYY') + ' - ' + dayjs(production.production.end).format('DD/MM/YYYY');
      }
      return '';
    }) ?? [],
);
const datasets = computed(() => productions.value.map((production) => production.consumption_per_unit) ?? []);
</script>
<template>
  <div class="border border-gray-200 rounded-lg p-4">
    <h3 class="text-xs text-gray-400 mb-4">{{ $t('production.kwh_by_unit', { value: area?.production_unit?.symbol ?? '--' }) }}</h3>
    <ui-chart-global
      :loading="loading"
      :data="{
        labels: timestamps,
        datasets: [
          {
            data: datasets,
            label: area?.name ?? '',
            backgroundColor: '#16a34a',
            borderRadius: 2,
            hoverBackgroundColor: '#78716c',
            minBarLength: 4,
          },
        ],
      }"
      :options="getOptions"
      type="bar"
    />
  </div>
</template>
