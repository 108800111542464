import type { ProductionsWithEPI } from '~/types/production';

interface State {
  areaId: number | null;
  productions: ProductionsWithEPI['productions'];
  plan: ProductionsWithEPI['plan'];
  loading: boolean;
  energyType: string;
  period: {
    start: Date | null;
    end: Date | null;
  };
  productionsDates: Date[];
  error: {
    message: string;
    status_code: number;
    error_code: string;
  } | null;
}

export const productionStore = defineStore({
  id: 'productionStore',
  state: (): State => ({
    areaId: null,
    productions: [],
    plan: null,
    loading: false,
    energyType: '',
    period: {
      start: null,
      end: null,
    },
    productionsDates: [],
    error: null,
  }),
  getters: {
    getProductions(): State['productions'] {
      return this.productions;
    },
    getProduction() {
      return (id: number): State['productions'][0] => this.productions.find((production) => production.production.id === id);
    },
    getProductionPlan(): ProductionsWithEPI['plan'] {
      return this.plan;
    },
    getAreaId(): State['areaId'] {
      return this.areaId;
    },
    getLoading(): State['loading'] {
      return this.loading;
    },
    getEnergyType(): State['energyType'] {
      return this.energyType;
    },
    getPeriod(): State['period'] {
      return this.period;
    },
    getProductionsDates(): State['productionsDates'] {
      return this.productionsDates;
    },
    getError(): State['error'] {
      return this.error;
    },
  },
  actions: {
    setProductions(productions: State['productions']): void {
      this.productions = productions;
    },
    setProductionPlan(plan: ProductionsWithEPI['plan']): void {
      this.plan = plan;
    },
    setAreaId(areaId: State['areaId']): void {
      this.areaId = areaId;
    },
    setLoading(loading: State['loading']): void {
      this.loading = loading;
    },
    setEnergyType(energyType: State['energyType']): void {
      this.energyType = energyType;
    },
    setPeriod(period: State['period']): void {
      this.period = period;
    },
    setProductionsDates(productionsDates: State['productionsDates']): void {
      this.productionsDates = productionsDates;
    },
    setError(error: State['error']): void {
      this.error = error;
    },
  },
});
